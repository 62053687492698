<template>
   <div class="bv-example-row mb-3">
      <b-card>
         <!-- 
        -
        -
        - HEADER MENU
        -
        -
       -->
         <div class="mx-1">
            <!-- Table Top -->
            <b-row>
               <!-- Per Page -->
               <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
               >
                  <label
                     class="text-bold"
                     style="margin: 0px 1em 0px 0px; font-size: 16px; font-weight: 700"
                     >{{ paramsHeader }} | {{ paramsSubHeader }} ({{
                        parmasDataCount
                     }})
                  </label>

                  <b-button variant="primary" v-b-modal.e-add-parametre>
                     <feather-icon icon="PlusIcon" class="mx-auto" />
                     Ajouter
                  </b-button>
               </b-col>

               <!-- Search -->
               <b-col cols="12" md="6">
                  <div class="d-flex align-items-center justify-content-end">
                     <b-form-input
                        v-model="state.filter"
                        class="d-inline-block mr-1"
                        placeholder="Rechercher par : client, montant, numero de facture"
                     />
                  </div>
               </b-col>
            </b-row>
         </div>

         <!-- 
        -
        -
        - CONTENT
        -
        -
       -->
         <b-row>
            <!-- 
        -  MENU - VERTICAL OPTIONS
       -->
            <b-col
               class="accordion border border-bottom-0 border-top-0 border-left-0 mt-2"
               cols="3"
               role="tablist"
            >
               <div v-for="route in routeParams" :key="route.id">
                  <span
                     block
                     v-b-toggle="'accordion-' + route.id"
                     class="d-flex justify-content-between pr-1 py-1"
                     variant="info"
                  >
                     <!-- <b-button variant="light"></b-button> -->
                     <span>
                        <feather-icon
                           :icon="route.icon"
                           size="16"
                           class="mx-1 cursor-pointer"
                        />
                        <span>{{ route.name }}</span>
                     </span>
                     <span>
                        <feather-icon
                           icon="ChevronDownIcon"
                           size="16"
                           class="mr- cursor-pointer"
                        />
                     </span>
                  </span>
                  <b-collapse
                     :id="'accordion-' + route.id"
                     visible
                     accordion="my-accordion"
                     role="tabpanel"
                  >
                     <div class="d-flex flex-column px-1">
                        <b-button
                           class="p-50 text-left"
                           :variant="
                              child.active === true ? 'primary' : 'light'
                           "
                           style="margin-bottom: 10px; border-radius: 5px; font-size: 12px"
                           v-for="child in route.children"
                           :key="child.id"
                           @click="
                              paramsData__data(child.id, route.name, child.name)
                           "
                           :disabled="child.id >= 100 ? true : false"
                           :class="child.id >= 100 ? '' : 'cursor-pointer'"
                        >
                           <span class="text-left">{{ child.name }} </span>
                        </b-button>
                     </div>
                  </b-collapse>
                  <hr />
               </div>
            </b-col>

            <!-- 
        -  CONTENT LIST  - INFO
       -->
            <b-col cols="9">
               <div class="">
                  <b-card-text>
                     <!-- Loader -->
                     <q-loader-table
                        :success="state.success"
                        :empty="state.empty"
                        :warring="state.warring"
                     />

                     <b-table
                        responsive
                        hover
                        primary-key="line"
                        show-empty
                        :items="paramsData"
                        :currentPage="state.currentPage"
                        :perPage="state.perPage"
                        :fields="tableColumns"
                        :filter="state.filter"
                        v-if="state.success === true"
                     >
                        <template #cell(libelle)="data">
                           <feather-icon
                              :icon="
                                 data.item.icone === null ||
                                 data.item.icone === ''
                                    ? 'ToolIcon'
                                    : data.item.icone
                              "
                              class="cursor-pointer"
                              size="16"
                           />
                           <span class="ml-50"> {{ data.item.libelle }} </span>
                        </template>

                        <template #row-details="data">
                           <b-card no-body>
                              <b-row class="w-100 ">
                                 <b-col
                                    md="12"
                                    xl="12"
                                    class=" w-100 "
                                    v-for="domaine in paramsDataSD"
                                    :key="domaine.line"
                                 >
                                    <div
                                       class="border-top  p-1"
                                       v-if="data.item.id === domaine.domaineId"
                                    >
                                       {{data.item.libelle}} :
                                       <b-badge variant="light-primary">
                                         {{ domaine.libelle }}
                                       </b-badge>
                                    </div>
                                 </b-col>
                              </b-row>
                           </b-card>
                        </template>

                        <!-- Column: Actions -->
                        <template #cell(actions)="data">
                           <div
                              class="d-flex align-items-center text-nowrap py-50"
                           >
                              <span
                                 class="d-flex cursor-pointer mr-1"
                                 v-if="
                                    routeParams[4].children[2].id === uidParams
                                 "
                              >
                                 <feather-icon
                                    v-b-modal.e-add-parametre
                                    @click="paramsAdd(data.item.id)"
                                    icon="PlusSquareIcon"
                                    size="16"
                                 />
                              </span>

                              <feather-icon
                                 v-b-modal.e-edit-parametre
                                 @click="paramsUpdate(data.item)"
                                 :id="
                                    `invoice-row-${data.item.id}-preview-icon`
                                 "
                                 icon="Edit3Icon"
                                 size="16"
                                 class="cursor-pointer"
                              />

                              <label
                                 v-if="
                                    routeParams[4].children[2].id === uidParams
                                 "
                                 :for="
                                    `invoice-row-${data.item.id}-hideAndShow-icon`
                                 "
                              >
                                 <feather-icon
                                    v-model="data.detailsShowing"
                                    @change="data.toggleDetails"
                                    :icon="
                                       data.detailsShowing
                                          ? 'EyeIcon'
                                          : 'EyeOffIcon'
                                    "
                                    size="16"
                                    class="cursor-pointer ml-1"
                                 />
                              </label>

                              <feather-icon
                                 @click="
                                    paramsDestroy(
                                       data.item.id,
                                       data.item.id_type
                                    )
                                 "
                                 :id="`invoice-row-${data.item.id}-send-icon`"
                                 icon="TrashIcon"
                                 class="mx-1 cursor-pointer"
                                 size="16"
                              />

                              <b-form-checkbox
                                 :id="
                                    `invoice-row-${data.item.id}-hideAndShow-icon`
                                 "
                                 v-model="data.detailsShowing"
                                 plain
                                 class="vs-checkbox-con hidden"
                                 @change="data.toggleDetails"
                              >
                              </b-form-checkbox>

                              <!-- Dropdown -->
                              <b-dropdown
                                 variant="link"
                                 toggle-class="p-0"
                                 no-caret
                                 :right="$store.state.appConfig.isRTL"
                              >
                                 <template #button-content>
                                    <feather-icon
                                       icon="MoreVerticalIcon"
                                       size="16"
                                       class="align-middle text-body"
                                    />
                                 </template>
                                 <!-- <b-dropdown-item @click="editFac(data.item.id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Modifier</span>
            </b-dropdown-item> -->
                                 <b-dropdown-item disabled>
                                    <feather-icon icon="TrashIcon" />
                                    <span class="align-middle ml-50">
                                       Supprimer</span
                                    >
                                 </b-dropdown-item>
                              </b-dropdown>
                           </div>
                        </template>
                     </b-table>

                     <!-- Paginator -->
                     <div class="mx-2 mb-2">
                        <b-row>
                           <b-col
                              cols="12"
                              sm="6"
                              class="d-flex align-items-center justify-content-center justify-content-sm-start"
                           >
                              <span class="text-muted"></span>
                           </b-col>
                           <!-- Pagination -->
                           <b-col
                              cols="12"
                              sm="6"
                              class="d-flex align-items-center justify-content-center justify-content-sm-end"
                           >
                              <b-pagination
                                 v-model="state.currentPage"
                                 :total-rows="paramsData.length"
                                 :per-page="state.perPage"
                                 first-number
                                 last-number
                                 class="mb-0 mt-1 mt-sm-0"
                                 prev-class="prev-item"
                                 next-class="next-item"
                                 align="right"
                              >
                                 <template #prev-text>
                                    <feather-icon
                                       icon="ChevronLeftIcon"
                                       size="18"
                                    />
                                 </template>
                                 <template #next-text>
                                    <feather-icon
                                       icon="ChevronRightIcon"
                                       size="18"
                                    />
                                 </template>
                              </b-pagination>
                           </b-col>
                        </b-row>
                     </div>
                  </b-card-text>
               </div>
            </b-col>
         </b-row>
      </b-card>

      <q-parametre-add
         :paramsData="paramsData"
         :paramsDataSD="paramsDataSD"
         :uidParams="uidParams"
         :uidDomaine="paramsDomaineId"
      />
      <q-parametre-edit
         :paramsData="paramsData"
         :paramsDataEdit="dataParamsEdit"
      />
   </div>
</template>

<script>
import { reactive, ref, computed, onMounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import axios from 'axios';
import URL from '@/views/pages/request';
import Ripple from 'vue-ripple-directive';
import qToast, { toast_error, toast_sucess } from '@/utils/qToast';
import Devis from '../devis/Devis.vue';
import QLoaderTable from '@/components/__partials/loaders/qLoaderTable.vue';
import QParametreAdd from './qParametreAdd.vue';
import moment from 'moment';
import QParametreEdit from './qParametreEdit.vue';

export default {
   components: {
      vSelect,
      QLoaderTable,
      QParametreAdd,
      QParametreEdit,
   },
   props: {
      dataCategorie: Object,
   },
   directives: {
      Ripple,
   },
   setup(props, { root }) {
      const paramsDomaineId = ref(null);
      const state = reactive({
         currentPage: 1,
         perPage: 10,
         filter: '',
         warring: false,
         empty: false,
         success: false,
      });
      const dataParamsEdit = ref({});
      const actionModal = '';
      const tableColumns = reactive([
         {
            key: 'line',
            label: 'N°',
         },
         {
            key: 'libelle',
            label: 'Libelle',
         },
         {
            key: 'created_at',
            label: "Date d'ajout",
         },
         {
            key: 'actions',
            label: 'Actions',
         },
      ]);
      const uidParams = ref(13);
      const paramsData = ref([]);
      const routeParams = reactive([
         {
            id: 1,
            name: 'Depense',
            icon: 'CornerLeftUpIcon',
            children: [
               {
                  id: 5,
                  name: 'Type de depense',
                  active: true,
               },
            ],
         },
         {
            id: 2,
            name: 'Factures',
            icon: 'LayersIcon',
            children: [
               {
                  id: 101,
                  name: 'Entete',
                  active: false,
               },
               {
                  id: 220,
                  name: 'taxe',
                  active: false,
               },
            ],
         },
         {
            id: 3,
            name: 'Emprumt',
            icon: 'CornerRightDownIcon',
            children: [
               {
                  id: 7,
                  name: 'Type de creancier',
                  active: false,
               },
            ],
         },
         {
            id: 4,
            name: 'Catalogues',
            icon: 'ShoppingBagIcon',
            children: [
               {
                  id: 4,
                  name: 'Categories',
                  active: false,
               },
               {
                  id: 102,
                  name: 'unité',
                  active: false,
               },
            ],
         },

         {
            id: 5,
            name: 'Inscriptions',
            icon: 'UserIcon',
            children: [
               {
                  id: 1,
                  name: 'Devise',
                  active: false,
               },
               {
                  id: 6,
                  name: 'projet',
                  active: false,
               },
               {
                  id: 2,
                  name: "Domaine d'activité",
                  active: false,
               },
               {
                  id: 3,
                  name: "Taille de l'entreprise",
                  active: false,
               },
            ],
         },

         {
            id: 6,
            name: 'Autres',
            icon: 'GitMergeIcon',
            children: [
               {
                  id: 103,
                  name: 'loading...',
                  active: false,
               },
            ],
         },
      ]);
      const isDataMounted = ref(false);
      const paramsHeader = ref('Depense');
      const paramsSubHeader = ref('Type depense');
      const paramsDataCache = ref([]);
      const paramsDataSD = ref([]);

      onMounted(() => {
         getParams();
      });

      const getParams = async () => {
         try {
            const { data } = await axios.get(URL.PARAMETRE_ALL, {
               headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
               },
            });

            if (data) {
               const getData = [];
               const getDataSousDomaine = [];
               console.log(data.parametre_liste);
               for (let i = 0; i < data.parametre_liste.length; i++) {
                  data.parametre_liste.reverse();
                  const el = data.parametre_liste[i];

                  if (el.parent_id !== null) {
                     getDataSousDomaine.push({
                        id: el.id,
                        id_type: el.type_parametre_id,
                        domaineId: el.parent_id,
                        libelle: el.libelle,
                        line: i + 1,
                        created_at_time: moment(el.created_at)
                           .toDate()
                           .getTime()
                           .toString(),
                     });
                     for (let i = 0; i < getDataSousDomaine.length; i++) {
                        const params = getDataSousDomaine[i];
                        params.line = i + 1;
                     }
                  } else {
                     getData.push({
                        id: el.id,
                        id_type: el.type_parametre_id,
                        line: i + 1,
                        icone: el.icone,
                        description: el.description,
                        domaineId: 0,
                        libelle: el.libelle,
                        created_at: format_date(el.created_at),
                        created_at_time: moment(el.created_at)
                           .toDate()
                           .getTime()
                           .toString(),
                     });
                     for (let i = 0; i < getData.length; i++) {
                        const params = getData[i];
                        params.line = i + 1;
                     }
                  }
               }

               paramsDataCache.value = getData;
               paramsDataSD.value = getDataSousDomaine;
               console.log('paramsDataSD.value : ', paramsDataSD.value);
               paramsData__data(5, 'Depénse', 'type de depense');
            }
         } catch (error) {
            console.log(error);
         }
      };

      const sourceParams = (paramsDataCache, uid) => {
         paramsData.value = paramsDataCache.filter((paramsData) => {
            return paramsData.id_type === uid;
         });
      };

      const parmasDataCount = computed(
         () => root.$store.state.qParametre.dataParametre.length
      );

      // Each get all paramettre
      const paramsData__data = (uid, header, subHeader) => {
         paramsHeader.value = header;
         paramsSubHeader.value = subHeader;
         uidParams.value = uid;

         sourceParams(paramsDataCache.value, uid);

         paramsData.value.forEach((el, index) => {
            el.line = index + 1;
         });

         for (let i = 0; i < routeParams.length; i++) {
            state.currentPage = 1;

            const child = routeParams[i].children;
            for (let r = 0; r < child.length; r++) {
               const r_child = child[r];

               r_child.active = false;
               if (r_child.id === uid) {
                  r_child.active = true;
               }
            }
         }

         if (paramsData.value === 0) {
            state.success = false;
            state.empty = true;
         } else {
            state.success = true;
            state.empty = false;
         }

         root.$store.commit(
            'qParametre/LIST_PARAMETRES_DATA',
            paramsData.value,
            {
               root: true,
            }
         );
      };

      // Delete uid parametres
      const paramsDestroy = async (id, id_type) => {
         root
            .$swal({
               title: `Êtes vous sûr de vouloir supprimé le parametre ${id}`,

               text: 'Aucun retour en arriére ne sera posible',

               icon: 'warning',

               showCancelButton: true,

               confirmButtonText: 'Oui',

               customClass: {
                  confirmButton: 'btn btn-primary',

                  cancelButton: 'btn btn-outline-danger ml-1',
               },

               buttonsStyling: false,
            })
            .then(async (response) => {
               if (response.isConfirmed === true) {
                  await axios
                     .post(URL.PARAMETRE_DESTROY, { id: id })
                     .then(({ data }) => {
                        if (data) {
                           paramsData.value = paramsDataCache.value.filter(
                              (params) => {
                                 return params.id !== id;
                              }
                           );
                           sourceParams(paramsData.value, id_type);

                           for (let i = 0; i < paramsData.value.length; i++) {
                              const params = paramsData.value[i];
                              params.line = i + 1;
                           }
                           root.$store.commit(
                              'qParametre/LIST_PARAMETRES_DATA',
                              paramsData.value,
                              {
                                 root: true,
                              }
                           );
                        }

                        toast_sucess(
                           root,
                           'success',
                           'top-right',
                           'Parametre suprimer avec sucéss !'
                        );
                     })
                     .catch((error) => {
                        toast_error(
                           root,
                           'danger',
                           'top-right',
                           'Oups, un erreur est survenu veillez ressayer !'
                        );

                        console.log(error);
                     });
               }
            });
      };

      const paramsAdd = (id) => {
         console.log(id);
         paramsDomaineId.value = id;
      };

      // Edit uid parametres
      const paramsUpdate = (data) => {
         dataParamsEdit.value = data;
         root.$store.commit('qParametre/INFO_PARAMETRES_DATA', data, {
            root: true,
         });
      };

      const format_date = (value) => {
         if (value) {
            return moment(String(value)).format('DD-MM-YYYY');
         }
      };

      return {
         routeParams,
         tableColumns,
         state,
         paramsData,
         getParams,
         uidParams,
         parmasDataCount,
         paramsData__data,
         paramsHeader,
         paramsSubHeader,
         format_date,
         actionModal,
         dataParamsEdit,
         paramsDestroy,
         paramsUpdate,
         paramsAdd,
         paramsDomaineId,
         paramsDataSD,
      };
   },
};
</script>

<style scoped>
/* CSS used here will be applied after bootstrap.css */
.panel a {
   color: #777;
}

.panel-scroll {
   max-height: 320px;
   overflow: hidden;
   overflow-y: auto;
}

.panel a:hover {
   text-decoration: none;
   color: #222;
}

.panel .table td {
   border-color: #f3f3f3;
}

.nameOfTheClass_2 {
   width: 50px !important;
}
</style>
