<template>
  <b-modal
    :id="'e-edit-parametre'"
    cancel-variant="outline-secondary"
    ok-only
    ok-title="Ajouter"
    cancel-title="Annuler"
    centered
    :title="'Modifier le parametre'"
    @ok="EditParametre"
  >
    <b-form>
      <!-- Libellé -->
      <b-form-group label="Libellé">
        <template #label> Libellé <span class="text-danger">*</span> </template>

        <b-form-input
          id="libelle"
          v-model="editParams.libelle"
          name="libelle"
          placeholder="Libellé de l'article"
        />
        <span
          class="text-danger"
          style="font-size: 12px"
          v-if="errorInput.path === 'libelle'"
        >
          {{ errorInput.message }}
        </span>
      </b-form-group>

      <!-- Icon du parametre -->
      <b-form-group label="Icone du parametre">
        <v-select
        @input="changeIconParams"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="_qIconList"
          input-id="invoice-data-client"
          :clearable="false"
          placeholder="Choisir une icone"
          v-model="editParams.icone"
        >
          <template v-slot:option="option">
            {{ option.label }}
            <feather-icon :icon="option.label" class="mx-auto" />
          </template>
        </v-select>
      </b-form-group>

      <!-- Description du produit -->
      <b-form-group>
        <label for="taxeValue">Description </label>
        <b-form-textarea
          id="textarea"
          v-model="editParams.description"
          placeholder="Entrer les details de l'article ici"
          rows="5"
          max-rows="6"
        >
        </b-form-textarea>
      </b-form-group>
    </b-form>

    <template #modal-footer>
      <b-button
        :disabled="state.loading === true ? true : false"
        variant="primary"
        @click.stop.prevent="EditParametre"
      >
        <span v-if="state.loading === false">Modifier</span>
        <b-spinner v-if="state.loading === true" label="Spinning"></b-spinner>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  reactive,
  ref,
  computed,
  watchEffect,
  watch,
} from '@vue/composition-api';
import vSelect from 'vue-select';
import axios from 'axios';
import URL from '@/views/pages/request';
import Ripple from 'vue-ripple-directive';
import qToast, { toast_sucess } from '@/utils/qToast';
import moment from 'moment';
import { _qIconList } from './qParametreService';
import _ from 'lodash';

export default {
  components: {
    vSelect,
  },
 
  props: {
    paramsData: Array,
    paramsDataEdit: Object,
  },
  directives: {
    Ripple,
  },
  setup(props, { root }) {
    const state = reactive({
      loading: false,
    });

    // const editParams  = {
    //   libelle: props.paramsDataEdit.libelle ? props.paramsDataEdit.libelle : '',
    //   icone: props.paramsDataEdit.icone ? props.paramsDataEdit.icone : '',
    //   description: props.paramsDataEdit.description ? props.paramsDataEdit.description : '',
    // }

    

    const editParams = computed(() =>{
      return {
        id: props.paramsDataEdit.id,
        libelle:  props.paramsDataEdit.libelle,
        icone: props.paramsDataEdit.icone,
        description: props.paramsDataEdit.description,
      }
    })

    const changeIconParams = (e) => {
      console.log(props.paramsDataEdit, e);
      props.paramsDataEdit.icone = e
    }

    const errorInput = reactive({
      path: '',
      message: '',
    });

  

    // *****
    // ****
    // FUNCTION POUR AJOUT D'ARTICLE
    // ****
    // *****
    const EditParametre = async () => {
      console.log(editParams.value);
      if (editParams.value.libelle === '') {
        errorInput.path = 'libelle';
        errorInput.message = 'Veillez entrer un libellé';
      } else {
        state.loading = true;
        const config = {
          headers: {
            Accept: 'application/json',
          },
        };

        const editData = {
          id: editParams.value.id,
          libelle: editParams.value.libelle,
          icone: editParams.value.icone,
          description: editParams.value.description,
        };

        let response = null;

        try {
          response = await axios.post(
            URL.PARAMETRE_UPDATE,
            editData,
            config
          );

          const data = response.data;

          if (response.data) {
            state.loading = false;
            const dataParams = props.paramsData;
            dataParams.forEach(params => {
              if(params.id === editData.id){
               
              params.libelle = editParams.value.libelle
             params.icone = editParams.value.icone
             params.description = editParams.value.description
              }
            });
           

            for (let i = 0; i < dataParams.length; i++) {
              const params = dataParams[i];
              params.line = i + 1;
            }

            root.$bvModal.hide('e-edit-parametre');
            toast_sucess(
              root,
              'success',
              'top-right',
              'Parametre modifier avec sucéss !'
            );

            root.$store.commit('qParametre/LIST_PARAMETRES_DATA', dataParams, {
              root: true,
            });

            editParams.value.libelle = '';
            editParams.value.icone = '';
            editParams.value.description = '';
          }
        } catch (error) {
          state.loading = false;
          console.log(error.message);
        }
      }
    };

    return {
      errorInput,
      _qIconList,
      state,
      EditParametre,
      editParams,
      changeIconParams
    };
  },
};
</script>

<style scoped></style>
